@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/fonts';

@mixin base-heading {
  margin: 0 0 1.25rem;
  line-height: 1.25;
  font-weight: 300;
  font-family: $font-family;
  letter-spacing: -1px;
  color: $text;
}

.h1 {
  @include base-heading();
  font-size: 9vw;

  @media (min-width: $breakpoint-small) {
    font-size: 5.5rem;
  }
}

.h2 {
  @include base-heading();
  font-size: 6.5vw;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-small) {
    font-size: 4rem;
  }
}

.h3 {
  @include base-heading();
  font-size: 5vw;
  font-weight: 100;
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
  color: $grey-dark;

  @media (min-width: $breakpoint-small) {
    font-size: 3rem;
  }
}

.h4 {
  @include base-heading();
  font-size: 4vw;
  font-weight: 100;
  margin-bottom: 1rem;
  letter-spacing: -0.3px;
  color: $grey-dark;

  @media (min-width: $breakpoint-small) {
    font-size: 2.5rem;
  }
}

.h5 {
  @include base-heading();
  font-size: 3.5vw;
  font-weight: 100;
  margin-bottom: 1.25rem;
  letter-spacing: -0.3px;
  color: $grey-dark;

  @media (min-width: $breakpoint-small) {
    font-size: 2rem;
  }
}

.h6 {
  @include base-heading();
  font-size: 3vw;
  font-weight: 100;
  margin-bottom: 1.25rem;
  letter-spacing: -0.3px;
  color: $grey-dark;

  @media (min-width: $breakpoint-small) {
    font-size: 1.5rem;
  }
}
