// typography
$text: #222;

// grey
$grey-lighter: #eee;
$grey-light: #bbb;
$grey-base: #999;
$grey-dark: #666;
$grey-darker: #333;

// primary
$primary-lighter: #aae8ff;
$primary-light: #6ed8ff;
$primary-base: #29b6f6;
$primary-dark: #007baf;
$primary-darker: #004d6d;