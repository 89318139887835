@import '../../styles/colors';

.footer {
  flex: 1 0 auto;
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  color: $grey-light;
}
